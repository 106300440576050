.navbar-custom {
  height: var(--havbar-height);
  margin-bottom: 20px;
  background-color: black;
}

@media screen and (max-width: 768px) {
  .navbar-custom {
    height: auto;
    flex-direction: column;
  }

  .navbar-custom > a {
    margin-bottom: 10px;
  }

  .navbar-custom .form-inline {
    width: 100%;
    flex-direction: column;
  }

  .navbar-custom .form-inline > * {
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0 !important;
  }
}

.account {
  padding: 6px 12px;
  border: 1px solid white;
  border-radius: 4px;
}
